import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith, Subject, switchMap } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { ITag } from '@models/tag';
import { SettingsSelectors } from '@store/settings';
import { debounceTime, filter, take, withLatestFrom } from 'rxjs/operators';
import { TagService } from '@core/services/resource/tag.service';
import { AppCoreSelectors } from '@store/app-core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IOrganisation } from '@models/organisation';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class CommonTagsComponent implements OnInit, OnDestroy {
    @Output() public tagChanged = new Subject<ITag>();

    @Input() updateFunction?: (data: any) => Observable<any>;
    @Input() tags: ITag[] = [];

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    tagCtrl = new FormControl();

    filteredTags: Observable<ITag[]>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private settingsSelectors: SettingsSelectors,
        private appCoreSelectors: AppCoreSelectors,
        private tagService: TagService
    ) {
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(''),
            filter(v => !!v),
            debounceTime(300),
            withLatestFrom(this.settingsSelectors.organisation$),
            switchMap(([tag, organisation]: [string | null, IOrganisation]) => {
                const exceptIds = this.tags.map(t => t.id);
                return this.tagService
                    .loadTags(organisation.id, { responseFormat: 'list' }, [
                        { param: 'name', operator: '=', value: `*${tag}*` },
                        { param: 'id', operator: '!=', value: exceptIds }
                    ])
                    .pipe(map((tags: ITag[]) => tags));
            })
        );
    }

    addTag(value: string, selectedTag?: ITag): void {
        if (!this.updateFunction) {
            this.tagCtrl.setValue(null);
            this.tagInput.nativeElement.value = '';

            if (selectedTag) {
                this.tags.push(selectedTag);
                return;
            }

            this.tags.push({
                id: 0,
                organisation_id: 0,
                name: value,
                color: ''
            });

            return;
        }

        if (value && this.tags.findIndex(t => t.name === value) === -1) {
            this.updateFunction({ tags: { attach: [value] } }).subscribe(response => {
                if (response?.tags_changes?.attached) {
                    response?.tags_changes?.attached.forEach(tag => {
                        this.tags.push(tag);
                        this._changeDetectorRef.detectChanges();
                    });
                }

                this.tagCtrl.setValue(null);
                this.tagInput.nativeElement.value = '';
            });
        }
    }

    entered(event: MatChipInputEvent): void {
        this.addTag((event.value || '').trim());
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);

        if (event.option.value) {
            this.addTag(event.option.value.name, event.option.value);
        }
    }

    remove(tag: ITag): void {
        if (!this.updateFunction) {
            this.tags.splice(
                this.tags.findIndex(t => t.name === tag.name),
                1
            );
            return;
        }

        this.updateFunction({ tags: { detach: [tag.name] } }).subscribe(response => {
            if (response?.tags_changes?.detached) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                response?.tags_changes?.detached.forEach(tag => {
                    this.tags.splice(
                        this.tags.findIndex(t => t.id === tag.id),
                        1
                    );
                });
            }
        });
    }

    colorChanged(tag: ITag, color: string): void {
        this.settingsSelectors.organisation$
            .pipe(
                take(1),
                switchMap(organisation => this.tagService.updateTag(organisation.id, tag.id, { color: color }))
            )
            .subscribe(updatedTag => {
                this.tags[this.tags.findIndex(t => t.id === updatedTag.id)].color = color;
                this.tagChanged.next(updatedTag);
            });
    }

    goToTag(tag: ITag): void {
        this.appCoreSelectors.type$
            .pipe(take(1), withLatestFrom(this.settingsSelectors.organisation$))
            .subscribe(([type, organisation]) => {
                this.router.navigate(['/o', organisation.slug, type, 'tags', tag.id]);
            });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
